import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import micro from '../assets/images/root/slides/micro.png';
import ingredients from '../assets/images/root/slides/ingredients.png';
import exports from '../assets/images/root/slides/exports.png';
import custom_formulation from '../assets/images/root/slides/custom_formulation.png';
import manufacturing from '../assets/images/root/slides/manufacturing.png';
import by_nature from '../assets/images/root/slides/by_nature.png';

import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = () => {
    return (
      <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} interval={4000}>
      <div>
      <img src={micro} alt="micro"  />
      </div>
      <div>
      <img src={ingredients} alt="ingredients"  />
      </div>
      <div>
      <img src={exports} alt="exports"  />
      </div>
      <div>
      <img src={custom_formulation} alt="custom_formulation"  />
      </div>
      <div>
      <img src={manufacturing} alt="manufacturing"  />
      </div>
      <div>
      <img src={by_nature} alt="by_nature"  />
      </div>
  </Carousel>
     
    );
  }

export default Slider;