import React from 'react';
import { Container, Row, Col  } from 'react-bootstrap';

const Footer = () => {
    return (
      <footer className="page-footer font-small blue pt-4">
  <Container fluid>
    <Row>
    <Col lg={3}>
                <span className="footer-list">Our USP's</span>
                <ul className="footer-items">
                    <li>Unique Products</li>
                    <li>Micro Packs</li>
                    <li>Affordable Price</li>
                    <li>Advanced Formulation</li>
                    <li>Mordern-Traditional Science</li>
                    <li>Pure and Organic</li>
                    <li>User First Design</li>
                    <li>Transparent Branding</li>
                    <li>Completely Customisable</li>
                </ul>
    </Col>
    <Col lg={3}>
                <span className="footer-list">Our Products</span>
                <ul className="footer-items">
                    <li>Advanced Hair Care Serum</li>
                    <li>Infused Almond Oil</li>
                    <li>Neem infused Hair Oil</li>
                    <li>Advanced Acne Care Serum</li>
                    <li>Activated Carbon (Charcoal) Face Mask</li>
                    <li>Activated Carbon (Charcoal) Body Scrub</li>
                    <li>Activated Carbon (Charcoal) Infused Hair Wash Powder</li>
                </ul>
    </Col>
    <Col lg={3}>
                <span className="footer-list">What do we offer ?</span>
                <ul className="footer-items">
                    <li>Private Label Manufacturing</li>
                    <li>Custom Formulation Manufacturing</li>
                    <li>Contract Manufacturing</li>
                    <li>Organic Cosmetic Products</li>
                    <li>Export of Cosmetic Products</li>
                    <li>Cosmetic Product Formulation</li>
                    <li>Product Branding</li>
                    <li>Testing Consultation</li>
                </ul>
    </Col>
    <Col lg={3}>
    <span className="footer-list">Contact</span>
                <ul className="footer-items">
                    <li><a href="tel:+91-95663-88399"><b>+91-95663-88399</b></a></li>
                    <li><a href="tel:+91-63623-59803"><b>+91-63623-59803</b></a></li>
                    <li><a href="mailto:cosmandles@gmail.com"><b>cosmandles@gmail.com</b></a></li>
                    <li><a href="mailto:sales@cosmandles.com"><b>sales@cosmandles.com</b></a></li>
                    <li><a href="mailto:karthik@cosmandles.com"><b>karthik@cosmandles.com</b></a></li>
                </ul>
    </Col>
  </Row>
</Container>
      <div className="footer-copyright text-center py-3"><b>&copy;</b> 2021 Copyrights :
          <a href="/"> COSMANDLES.com</a>
      </div>
  
  </footer>
    );
  }

export default Footer;