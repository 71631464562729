import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCRPVdggxiVAu_5ttfpJf4trRmAf7JZ9cQ",
authDomain: "cosmandles.firebaseapp.com",
projectId: "cosmandles",
storageBucket: "cosmandles.appspot.com",
messagingSenderId: "602162632604",
appId: "1:602162632604:web:467352c96caf625fba1a59",
measurementId: "G-W45Q8W7V0H"
  };

  firebase.initializeApp(firebaseConfig);
  var db = firebase.firestore();
    
  export default db;