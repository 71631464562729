import React from 'react';
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import logo_horizontal from '../assets/images/logo_horizontal.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUsers, faEnvelopeOpenText, faBoxOpen, faTools, faTint, faLeaf, faEyeDropper, faAllergies, faHatCowboy, faTag, faAtom, faFileSignature, faHandshake, faShip, faFillDrip, faCannabis, faDisease, faDotCircle, faCheese } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
  setInterval(() => {
    if(window.location.pathname.split("/").length > 2) {
    var activeParentList = document.querySelectorAll('.parentMenuActive');
    if (activeParentList !== undefined && activeParentList.length > 0) {
      activeParentList.forEach(parentItem => {
        var selectedSubmenu = document.querySelectorAll('[sub-routes*="' + window.location.pathname + '"]')[0];
        if (selectedSubmenu === undefined) {
          parentItem.className = parentItem.className.replace(' parentMenuActive', '');
        }
        else {
          if (parentItem.getAttribute("sub-routes") !== selectedSubmenu.getAttribute("sub-routes")) {
            parentItem.className = parentItem.className.replace(' parentMenuActive', '');
          }
        }
      });
    }

    var submenuItem = document.querySelectorAll('[sub-routes*="' + window.location.pathname + '"]')[0];
    if (submenuItem !== undefined) {
      if (submenuItem.className.indexOf('parentMenuActive' > -1)) {
        if (window.location.pathname !== "/") {
          submenuItem.className += ' parentMenuActive';
        }
      }
    }

    var multiSelectedParent = document.querySelectorAll('.parentMenuActive,.parentMenuActive')[0];
    if (multiSelectedParent !== undefined) {
      multiSelectedParent.className = multiSelectedParent.className.replace("parentMenuActive parentMenuActive", "parentMenuActive");
    }

  }
  else {
    var inactiveParentList = document.querySelectorAll('.parentMenuActive');
    if (inactiveParentList !== undefined && inactiveParentList.length > 0) {
      inactiveParentList.forEach(parentItem => {
        parentItem.className = parentItem.className.replace(' parentMenuActive', '');
      });
    }
  }
  }, 1000);
  return (
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo_horizontal} alt="logo" width="250" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" defaultActiveKey={window.location.pathname}>
            <Nav.Link as={Link} to="/" eventKey="/"><FontAwesomeIcon icon={faHome} /> Home</Nav.Link>
            <Nav.Link as={Link} to="/about-us" eventKey="/about-us"><FontAwesomeIcon icon={faUsers} /> About Us</Nav.Link>
            {/* <NavDropdown sub-routes={["/naturals/oils", "/naturals/clays", "/naturals/butters", "/naturals/extracts", "/naturals/dries", "/naturals/wax"]} title={<span><FontAwesomeIcon icon={faLeaf} /> Naturals</span>} id="collasible-nav-dropdown_products">
              <NavDropdown.Item as={Link} to="/naturals/oils" eventKey="/naturals/oils"><FontAwesomeIcon icon={faTint} /> Oils</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/naturals/clays" eventKey="/naturals/clays"><FontAwesomeIcon icon={faDisease} /> Clays</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/naturals/butters" eventKey="/naturals/butters"><FontAwesomeIcon icon={faDotCircle} /> Butters</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/naturals/extracts" eventKey="/naturals/extracts"><FontAwesomeIcon icon={faFillDrip} /> Extracts</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/naturals/dries" eventKey="/naturals/dries"><FontAwesomeIcon icon={faCannabis} /> Dries</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/naturals/wax" eventKey="/naturals/wax"><FontAwesomeIcon icon={faCheese} /> Wax</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown sub-routes={["/products/beauty-care", "/products/skin-care", "/products/hair-care"]} title={<span><FontAwesomeIcon icon={faBoxOpen} /> Products</span>} id="collasible-nav-dropdown_products">
              <NavDropdown.Item as={Link} to="/products/beauty-care" eventKey="/products/beauty-care"><FontAwesomeIcon icon={faEyeDropper} /> Beauty Care</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/products/skin-care" eventKey="/products/skin-care"><FontAwesomeIcon icon={faAllergies} /> Skin Care</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/products/hair-care" eventKey="/products/hair-care"><FontAwesomeIcon icon={faHatCowboy} /> Hair Care</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown sub-routes={["/services/private-labelling", "/services/custom-formulation", "/services/contract-manufacturing", "/services/collaborative-labelling", "/services/exports"]} title={<span><FontAwesomeIcon icon={faTools} /> Services</span>} id="collasible-nav-dropdown_services">
              <NavDropdown.Item as={Link} to="/services/private-labelling" eventKey="/services/private-labelling"><FontAwesomeIcon icon={faTag} /> Private Labelling</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/services/collaborative-labelling" eventKey="/services/collaborative-labelling"><FontAwesomeIcon icon={faHandshake} /> Collaborative Labelling</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/services/custom-formulation" eventKey="/services/custom-formulation"><FontAwesomeIcon icon={faAtom} /> Custom Formulation</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/services/contract-manufacturing" eventKey="/services/contract-manufacturing"><FontAwesomeIcon icon={faFileSignature} /> Contract Manufacturing</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/services/exports" eventKey="/services/exports"><FontAwesomeIcon icon={faShip} /> Exports</NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link as={Link} to="/contact-us" eventKey="/contact-us"><FontAwesomeIcon icon={faEnvelopeOpenText} /> Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;