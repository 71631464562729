import React from 'react';
import Slider from '../../widget/slider'
import Splitter from '../../widget/splitter'

import '../../css/root/home.css';

const Home = () => {
    return (
      <div className="home">
      <Slider />
      <Splitter />
      <div className="App">
      <div className="head">
      <div className="mb-3"><b>@COSMANDLES</b> | Organic Cosmetic Manufacturer and Exporter
      <br></br><br></br>
      All our products are made with Finest quality ingredients to give you the best experience, <br></br>our products are completely <b>Hand Made</b>, safe as it has no harmful chemicals and made with complete care.
      </div>
    </div>
      </div>
      </div>
    );
  }

export default Home;