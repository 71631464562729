import React from 'react';
import exports from '../../assets/images/services/exports.png';
import Splitter from '../../widget/splitter'

import '../../css/services/exports.css';

const Exports = () => {
  return (
    <div className="exports">
      <img src={exports} alt="exports" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default Exports;