import React from 'react';
import oils from '../../assets/images/naturals/oils.png';
import Splitter from '../../widget/splitter'

import '../../css/naturals/oils.css';

const Oils = () => {
  return (
    <div className="oils">
      <img src={oils} alt="oils" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default Oils;