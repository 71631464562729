import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import './App.css';

import Header from './layout/header'
import Footer from './layout/footer'
import Splitter from './widget/splitter'

import Home from './pages/root/home'
import AboutUs from './pages/root/about-us'

import Oils from './pages/naturals/oils'
import Clays from './pages/naturals/clays'
import Butters from './pages/naturals/butters'
import Extracts from './pages/naturals/extracts'
import Dries from './pages/naturals/dries'
import Wax from './pages/naturals/wax'

import BeautyCare from './pages/products/beauty-care'
import SkinCare from './pages/products/skin-care'
import HairCare from './pages/products/hair-care'

import PrivateLabelling from './pages/services/private-labelling'
import CustomFormulation from './pages/services/custom-formulation'
import ContractManufacturing from './pages/services/contract-manufacturing'
import CollaborativeLabelling from './pages/services/collaborative-labelling'
import Exports from './pages/services/exports'

import ContactUs from './pages/root/contact-us'

function App() {
  return (
    <BrowserRouter>
     <Header />
     <div className="mainContent">
      <Switch>
        <Route path="/" exact >
          <Home />
        </Route>
        <Route path="/about-us" exact >
          <AboutUs />
        </Route>

        <Route path="/naturals/oils" exact >
          <Oils />
        </Route>
        <Route path="/naturals/clays" exact >
          <Clays />
        </Route>
        <Route path="/naturals/butters" exact >
          <Butters />
        </Route>
        <Route path="/naturals/extracts" exact >
          <Extracts />
        </Route>
        <Route path="/naturals/dries" exact >
          <Dries />
        </Route>
        <Route path="/naturals/wax" exact >
          <Wax />
        </Route>

        <Route path="/products/beauty-care" exact >
          <BeautyCare />
        </Route>
        <Route path="/products/skin-care" exact >
          <SkinCare />
        </Route>
        <Route path="/products/hair-care" exact >
          <HairCare />
        </Route>

        <Route path="/services/private-labelling" exact >
          <PrivateLabelling />
        </Route>
        <Route path="/services/custom-formulation" exact >
          <CustomFormulation />
        </Route>
        <Route path="/services/contract-manufacturing" exact >
          <ContractManufacturing />
        </Route>
        <Route path="/services/collaborative-labelling" exact >
          <CollaborativeLabelling />
        </Route>
        <Route path="/services/exports" exact >
          <Exports />
        </Route>

        <Route path="/contact-us" exact >
          <ContactUs />
        </Route>

        <Route path="/*" render={() => (<Redirect to="/" />)} />
      </Switch>
      <Splitter />
      <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
