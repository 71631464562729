import React from 'react';
import contract_manufacturing from '../../assets/images/services/contract-manufacturing.png';
import Splitter from '../../widget/splitter'

import '../../css/services/contract-manufacturing.css';

const ContractManufacturing = () => {
  return (
    <div className="contract-manufacturing">
      <img src={contract_manufacturing} alt="contract-manufacturing" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default ContractManufacturing;