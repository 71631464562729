import React from 'react';
import skin_care from '../../assets/images/products/skin-care.png';
import Splitter from '../../widget/splitter'

import '../../css/products/skin-care.css';

const SkinCare = () => {
  return (
    <div className="skin-care">
      <img src={skin_care} alt="skin-care" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default SkinCare;