import React from 'react';
import { Container, Row, Col  } from 'react-bootstrap';

import db from '../../data/firestore';

import Splitter from '../../widget/splitter'

import '../../css/root/contact-us.css';

const ContactUs = () => {
  var contactSubmit = function (e) {
    e.preventDefault();
    var name = document.getElementById("name").value;
    var company = document.getElementById("company").value;
    var email = document.getElementById("email").value;
    var phone = document.getElementById("phone").value;
    var message = document.getElementById("message").value;
    db.collection("message").add({
      name: name,
      company: company,
      email: email,
      phone: phone,
      message: message
  })
  .then((docRef) => {      
    alert('Thanks for your message, we will connect with you shortly.');
    document.getElementById("contactForm").reset();
  })
  .catch((error) => {
      console.error("Error adding document: ", error);
  });
}
  return (
    <div className="contact-us">
      <Splitter />      
      <Container fluid>
      <Row>
      <Col sm={12}>
      <div className="page-title">CONTACT US</div>
      </Col>
      </Row>
      <Row>
      <Col sm={12}>
      <Splitter />   
      </Col>
      </Row>
      <Row>
      <Col sm={12}>
      
        <div className="address-profile">
        <Row>
      <Col sm={4}>
          <div className="address-block">
          <b>COSMANDLES Home and Personal Care (OPC) Private Limited</b><br />
          Director : <b>Karthik Ramesh</b><br />
          No.53/D, Officers Line,<br />
          Mettupalayam,<br />
          Vaniyambadi - 635754<br />
          Thirupattur Dist (Vellore)<br />
          Tamil Nadu. INDIA.
          </div>
          </Col>
      <Col sm={4}>
        <div className="contact-block">
        <b>Phone Nmber :</b><br />
        <a href="tel:+91-95663-88388">+91 95663 88399</a><br />
        <a href="tel:+91-63623-59803">+91 63623 59803</a><br /><br />
        <b>Email Address :</b><br />
        <a href="mailto:cosmandles@gmail.com">cosmandles@gmail.com</a><br />
        <a href="mailto:sales@cosmandles.com">sales@cosmandles.com</a><br />
        <a href="mailto:karthik@cosmandles.com">karthik@cosmandles.com</a>
        </div>
        </Col>
        <Col sm={4}>
        <div className="brand-block">
        <b>OUR BRANDS :</b><br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.blackandz.com">BLACKandZ</a>
        <br /><br />
        </div>
        </Col>
      </Row>
        </div>
        
      </Col>
      </Row>
      <Row>
      <Col sm={12}>
      <Splitter />   
      </Col>
      </Row>
      <Row>
      <Col sm={6}>
      <div className="map-profile">
      <iframe title="COSMANDLES" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56022.31970548817!2d179.96498107910156!3d28.64788980000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8a9b1a8cbd843ab%3A0x911e5766ffc15844!2sCOSMANDLES%20Home%20and%20Personal%20Care%20(OPC)%20Private%20Limited!5e0!3m2!1sen!2sin!4v1643684155496!5m2!1sen!2sin" loading="lazy"></iframe>
      </div>
      </Col>
      <Col sm={6}>
        <form id="contactForm" onSubmit={contactSubmit} >
      <div className="form-profile">
      <Row>
      <Col sm={6}>
        <input type="text" id="name" placeholder="Name **" required  />
      </Col>
      <Col sm={6}>
      <input type="text" id="company" placeholder="Company **" required  />
      </Col>
      </Row>
      <Row>
      <Col sm={6}>
      <input type="email" id="email" placeholder="Email Address **" required  />
      </Col>
      <Col sm={6}>
      <input type="tel" id="phone" placeholder="Phone Number **" required />
      </Col>
      </Row>
      <Row>
      <Col sm={12}>
      <textarea id="message" placeholder="Message **" required  />
      </Col>
      </Row>
      <Row>
      <Col sm={12}>
        <div className="text-right">
        <button className="btn btn-light">Submit</button>
        </div>
      </Col>
      </Row>
      </div>
      </form>
      </Col>
      </Row>
      </Container>
      </div>
  );
  }

export default ContactUs;