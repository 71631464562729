import React from 'react';
import clays from '../../assets/images/naturals/clays.png';
import Splitter from '../../widget/splitter'

import '../../css/naturals/clays.css';

const Clays = () => {
  return (
    <div className="clays">
      <img src={clays} alt="clays" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default Clays;