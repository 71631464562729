import React from 'react';
import wax from '../../assets/images/naturals/wax.png';
import Splitter from '../../widget/splitter'

import '../../css/naturals/wax.css';

const Wax = () => {
  return (
    <div className="wax">
      <img src={wax} alt="wax" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default Wax;