import React from 'react';
import extracts from '../../assets/images/naturals/extracts.png';
import Splitter from '../../widget/splitter'

import '../../css/naturals/extracts.css';

const Extracts = () => {
  return (
    <div className="extracts">
      <img src={extracts} alt="extracts" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default Extracts;