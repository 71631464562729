import React from 'react';
import hair_care from '../../assets/images/products/hair-care.png';
import Splitter from '../../widget/splitter'

import '../../css/products/hair-care.css';

const HairCare = () => {
  return (
    <div className="hair-care">
      <img src={hair_care} alt="hair-care" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default HairCare;