import React from 'react';
import private_labelling from '../../assets/images/services/private-labelling.png';
import Splitter from '../../widget/splitter'

import '../../css/services/private-labelling.css';

const PrivateLabelling = () => {
  return (
    <div className="private-labelling">
      <img src={private_labelling} alt="private-labelling" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default PrivateLabelling;