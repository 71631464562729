import React from 'react';
import butters from '../../assets/images/naturals/butters.png';
import Splitter from '../../widget/splitter'

import '../../css/naturals/butters.css';

const Butters = () => {
  return (
    <div className="butters">
      <img src={butters} alt="butters" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default Butters;