import React from 'react';
import collaborative_labelling from '../../assets/images/services/collaborative-labelling.png';
import Splitter from '../../widget/splitter'

import '../../css/services/collaborative-labelling.css';

const CollaborativeLabelling = () => {
  return (
    <div className="collaborative-labelling">
      <img src={collaborative_labelling} alt="collaborative-labelling" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default CollaborativeLabelling;