import React from 'react';
import custom_formulation from '../../assets/images/services/custom-formulation.png';
import Splitter from '../../widget/splitter'

import '../../css/services/custom-formulation.css';

const CustomFormulation = () => {
  return (
    <div className="custom-formulation">
      <img src={custom_formulation} alt="custom-formulation" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default CustomFormulation;