import React from 'react';
import { Container, Row, Col  } from 'react-bootstrap';

import about_us from '../../assets/images/root/about-us.png';
import Splitter from '../../widget/splitter'

import '../../css/root/about-us.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <img src={about_us} alt="butters" width="100%" />
      <Splitter />
      <Container fluid>
      <Row>
      <Col sm={12}>
      
        <div className="about-profile">
          We provide <b>Best Support</b> on exporting cosmetics from India, our core value is to 
          use traditional cosmetic items in a mordern way, So we bring in value to our tradition and
          get the best out of it.<br />

          <div className="about-title">
            Why Choose Us ?
          </div>

          <ul>
            <li>Best Quality Products</li>
            <li>Easy and Hassle free Export</li>
            <li>Low Cost</li>
            <li>Custom Branding</li>
            <li>Custom Packing</li>
            <li>Standard and On Time Shipment</li>
          </ul>
        </div>
        
      </Col>
      </Row>
      <Row>
      <Col sm={12}>
      <Splitter />   
      </Col>
      </Row> 
      <Row>
      <Col sm={12}>
      <Splitter />   
      </Col>
      </Row> 
      <Row>
      <Col sm={12}>
          <div className="about-title">
            Our Expor Modes :
          </div>
          <ul>
            <li>EXW (Ex Works)</li>
            <li>FCA (Free Carrier)</li>
            <li>FAS (Free Alongside Ship)</li>
            <li>FOB (Free On Board)</li>
          </ul>
      </Col>
      </Row>
      <Row>
      <Col sm={12}>
      <Splitter />   
      </Col>
      </Row>
      <Row>
      <Col sm={12}>
      <div className="map-profile">
      <iframe title="COSMANDLES" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56022.31970548817!2d179.96498107910156!3d28.64788980000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8a9b1a8cbd843ab%3A0x911e5766ffc15844!2sCOSMANDLES%20Home%20and%20Personal%20Care%20(OPC)%20Private%20Limited!5e0!3m2!1sen!2sin!4v1643684155496!5m2!1sen!2sin" loading="lazy"></iframe>
      </div>
      </Col>
      </Row>
      
      </Container>
      </div>
  );
  }

export default AboutUs;