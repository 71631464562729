import React from 'react';
import dries from '../../assets/images/naturals/dries.png';
import Splitter from '../../widget/splitter'

import '../../css/naturals/dries.css';

const Dries = () => {
  return (
    <div className="dries">
      <img src={dries} alt="dries" width="100%" />
      <Splitter />
      {/* add data here */}
      </div>
  );
  }

export default Dries;